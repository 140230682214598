import React from 'react'
import { Flex } from 'rebass/styled-components'
import shortid from 'shortid';

// COMPONENTS
import SEO from './../components/templates/seo';
import Section from './../components/atoms/section';
import Heading from './../components/atoms/heading';
import Text from './../components/atoms/text';

export default function FAQ(props) {

    const questions = [
        {
            question: `Jak trzeba przygotować się do pierwszej rozmowy na 
            temat projektu kominka ?`,
            answer: `- Należy zebrać informacje między 
            innymi na temat: wymiarów pomieszczenia, w 
            którym ma znaleźć się urządzenie grzewcze, 
            powierzchni, jaką chcemy ogrzać, rodzaju i wymiarów komina, 
            rodzaju wentylacji w domu.`
        },
        {
            question: `Czy te urządzenia grzewcze spełniają normy eko projektu ?`,
            answer: `- Tak. Wszystkie urządzenia w naszej ofercie spełniają normy eko-projektu.`
        },
        {
            question: `Jaki jest czas realizacji ?`,
            answer: `- Czas realizacji danego zlecenia zależy 
            od kilku czynników między innymi od wyboru urządzenia 
            grzewczego i jego dostępności, więc jest wyznaczany indywidualnie.`
        },
        {
            question: `Jakie materiały zostaną użyte do budowy zabudowy kominkowej ?`,
            answer: `- Zabudowa kominkowa jest zbudowana z płyt g-k oraz wysokiej klasy płyt warsenowskich.`
        }
    ]

    const faq_section_props = {
        sx: {
            width: '100%',
            height: 'auto',
            flexFlow: 'column nowrap',
            marginBottom: '2.5rem'
        },
        padding: '0 1.25rem'
    }
    
    const faq_heading_props = {
        as: 'h1',
        background_text: 'Pytania',
        margin: '2rem auto 3.5rem auto'
    }

    const answers = questions.map(item => 
        <Flex key={shortid.generate()} sx={{
            flexFlow: 'column nowrap',
            margin: '1rem 0'
        }}
        data-sal='slide-up'
        data-sal-duration='600'
        data-sal-delay='0'
        data-sal-easing='cubic-bezier(0.165, 0.84, 0.44, 1)'>
            <Heading as='h2' textAlign='left' margin='0' fontSize={[22, 24]}>{item.question}</Heading>
            <Text as='p' margin='1.5rem 0'>{item.answer}</Text>
        </Flex>
    )

    return (
        <>
            <SEO title='FAQ' />
            <Section {...faq_section_props}>
                <Heading {...faq_heading_props}>FAQ</Heading>
                {answers}
            </Section>
        </>
    )
}
